@media (max-width: 767px) {
    /* line 181, ../../../sponDon TI/Running Project/259.Finance_HTML/assets/scss/_common.scss */
    .logo-icon {
      width: 50px !important;
      height: 50px !important;
    }
}

.logo-icon {
    width: 80px;
    height: 80px;
  }